import { useSelector, useDispatch } from "react-redux";
import OneSignal from "react-onesignal";
import {
  setUser,
  initialState,
  setOpenModal,
  setOpenProfileModal,
} from "store/auth/userSlice";
import { apiSignIn, apiSignOut, apiSignUp } from "services/AuthService";
import { onSignInSuccess, onSignOutSuccess } from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
// import { REDIRECT_URL_KEY } from "constants/app.constant"
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";
import React, { useState } from "react";
import { Button, Dialog } from "components/ui";
import { setOpenUpdateLogModal } from "store/auth/userSlice";

//one signal
import { initializeOneSignal } from "utils/one_signal/OneSignalSetup";

function useAuth() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const query = useQuery();

  const { token, signedIn } = useSelector((state) => state.auth.session);

  const saveUsername = (username, avatarUrl) => {
    const recentUsernames =
      JSON.parse(localStorage.getItem("recentUsernames")) || [];

    // Check if the username already exists
    const userIndex = recentUsernames.findIndex(
      (user) => user.username === username
    );

    if (userIndex !== -1) {
      // Update the existing user's avatar URL
      recentUsernames[userIndex].avatarUrl = avatarUrl;
    } else {
      // Add new user to the list
      recentUsernames.push({ username, avatarUrl });

      // Keep how many recent usernames
      if (recentUsernames.length > 4) {
        recentUsernames.shift();
      }
    }

    localStorage.setItem("recentUsernames", JSON.stringify(recentUsernames));
  };

  const saveCommPermission = (roles, commission_permission) => {
    localStorage.setItem("user_role", JSON.stringify(roles));
    localStorage.setItem(
      "user_commission_permission",
      JSON.stringify(commission_permission)
    );
    localStorage.setItem("new_role", roles);
    localStorage.setItem("new_permission", commission_permission);
  };

  const signIn = async (values) => {
    try {
      const { data } = await apiSignIn(values);
      if (data) {
        const {
          token,
          roles,
          username,
          name,
          id,
          avatar,
          purchase_stock_permission,
          skip_purchase_permission,
          label_name,
          commission_permission,
        } = data.data;
        if (token) {
          initializeOneSignal(dispatch, navigate);
        }
        dispatch(onSignInSuccess(token));
        if (username === process.env.REACT_APP_SPECIAL_GUEST) {
          //special_email
          dispatch(
            setUser({
              avatar: avatar,
              username: username,
              authority: [roles, "SPECIAL", username],
              email: username,
              name: name,
              id: id,
              role: roles,
              purchase_stock_permission: purchase_stock_permission,
              skip_purchase_permission: skip_purchase_permission,
              label_name: label_name,
              commission_permission: commission_permission,
            })
          );
          // const redirectUrl = query.get(REDIRECT_URL_KEY);
          // navigate(redirectUrl ? redirectUrl : appConfig.otherEntryPath);
          navigate(appConfig.otherEntryPath);
        } else if (roles == "SUPERADMIN" || roles == "ADMIN") {
          dispatch(
            setUser({
              avatar: avatar,
              username: username,
              authority: [roles, username],
              email: username,
              name: name,
              id: id,
              role: roles,
              purchase_stock_permission: purchase_stock_permission,
              skip_purchase_permission: skip_purchase_permission,
              label_name: label_name,
              commission_permission: commission_permission,
            })
          );
          // const redirectUrl = query.get(REDIRECT_URL_KEY)
          // navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
          if (roles == "SUPERADMIN") {
            navigate(appConfig.companyDashboard);
          } else {
            navigate(appConfig.authenticatedEntryPath);
          }
        } else if (roles == "DEALER" || roles == "SUBDEALER") {
          dispatch(
            setUser({
              avatar: avatar,
              username: username,
              authority: [roles, username],
              email: username,
              name: name,
              id: id,
              role: roles,
              purchase_stock_permission: purchase_stock_permission,
              skip_purchase_permission: skip_purchase_permission,
              label_name: label_name,
              commission_permission: commission_permission,
            })
          );

          const redirectToAfterLogin = localStorage.getItem(
            "redirectToAfterLogin"
          );

          if (redirectToAfterLogin && redirectToAfterLogin !== "") {
            navigate(redirectToAfterLogin);
            localStorage.removeItem("redirectToAfterLogin");
          } else {
            navigate(appConfig.otherEntryPath);
          }
        } else {
          dispatch(
            setUser({
              avatar: avatar,
              username: username,
              authority: [roles, username],
              email: username,
              name: name,
              id: id,
              role: roles,
              purchase_stock_permission: purchase_stock_permission,
              skip_purchase_permission: skip_purchase_permission,
              label_name: label_name,
              commission_permission: commission_permission,
            })
          );
          // const redirectUrl = query.get(REDIRECT_URL_KEY);
          // navigate(redirectUrl ? redirectUrl : appConfig.otherEntryPath);
          navigate(appConfig.otherEntryPath);
        }

        // dispatch(setOpenUpdateLogModal(true));

        // Save the username in localStorage
        const avatarUrl = avatar[0]?.api_url; // Get the first avatar's URL
        saveUsername(username, avatarUrl);
        saveCommPermission(roles, commission_permission);

        return {
          status: "success",
          message: "",
        };
      }
    } catch (errors) {
      if (errors && errors.response.status === 422) {
        return {
          status: "failed",
          message: errors?.response?.data?.errors?.error || errors.toString(),
        };
      }
      if (errors && errors.response.status === 410) {
        return {
          status: "version_failed",
          message: errors?.response?.data?.errors?.error || errors.toString(),
        };
      }
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const signUp = async (values) => {
    try {
      const resp = await apiSignUp(values);
      if (resp.data) {
        const { token } = resp.data;
        dispatch(onSignInSuccess(token));
        if (resp.data.user) {
          dispatch(
            setUser(
              resp.data.user || {
                avatar: "",
                username: "Anonymous",
                authority: ["USER"],
                email: "",
              }
            )
          );
        }
        // const redirectUrl = query.get(REDIRECT_URL_KEY)
        // navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
        return {
          status: "success",
          message: "",
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const handleSignOut = () => {
    // Retrieve the recentUsernames from localStorage
    const recentUsernames = localStorage.getItem("recentUsernames");

    localStorage.clear();

    // Restore the recentUsernames back to localStorage
    if (recentUsernames) {
      localStorage.setItem("recentUsernames", recentUsernames);
    }
    localStorage.removeItem("redirectToAfterLogin");

    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async () => {
    await apiSignOut();
    handleSignOut();
    OneSignal.logout();
  };

  const changePassword = async (values) => {
    dispatch(setOpenModal(true));
  };

  const editProfile = async (values) => {
    dispatch(setOpenProfileModal(true));
  };

  return {
    authenticated: token && signedIn,
    signIn,
    signUp,
    signOut,
    changePassword,
    editProfile,
  };
}

export default useAuth;
